body,
.app-container {
  font-family: 'Montserrat', sans-serif !important;
}

.nav-link{
  color: #ffffff !important;
}

.custom-navbar.scrolled .nav-link{
  color: #000000 !important;
}

.custom-dropdown-navbar.nav-item.dropend .dropdown-toggle.nav-link{
  color: #000000 !important;
}

.custom-dropdown-navbar.nav-item.dropend .dropdown-toggle.nav-link:hover{
  color: #d19429 !important;
}

/* Custom navbar collapse bootstrap */
@media (max-width: 767px ) {
  .navbar-collapse{
    margin-left: -5%;
    margin-right: -5%;
    padding-left: 5%;
    background-color: #ffffff;
  }
  .navbar-collapse.menu-right{
    height: 100vh;
  }
  .nav-link{
    color: #000000 !important;
  }
}
/* End css */

.logo-navbar {
  height: auto;
  width: 125px;
}

.logo-navbar-mobile {
  height: auto;
  width: 75px;
}

.dropdown-menu.show {
  border: none;
  background-color: #fff;
}

.home-custom-h2 {
  font-size: 25px;
  padding-top: 53vh;
  padding-left: 4vw;
  font-weight: 100;
  color: #fff;
  line-height: 1;
}

.home-custom-h2-2 {
  font-size: 45px;
  font-weight: bold;
  padding-left: 4%;
  color: #fff;
  line-height: 1;
}

.home-custom-h2-V2 {
  font-size: 25px;
  padding-top: 50vh;
  padding-left: 4vw;
  font-weight: bold;
  color: #fff;
}

.home-custom-p {
  padding-left: 4vw;
  font-size: 20px;
  color: #fff;
  max-width: 65%;
  line-height: 1.4;
}

.home-custom-p-V2 {
  font-size: 15px;
  color: #fff;
  max-width: 55%;
  line-height: 1.2;
}

@media (max-width: 767px) {
  .home-custom-h2{
    padding-top: 20vh;
  }
  .home-custom-p {
    text-align: left;
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .custom-testimony-row{
    margin: 0;
  }

  .home-custom-h2 {
    font-weight: 100;
  }
  .home-custom-h2-V2 {
    padding-top: 60%;
  }

  .home-custom-p {
    max-width: 90%;
  }

  .home-custom-p-V2 {
    max-width: 90%;
  }
}

.custom-fond-image {
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.custom-fond-image-V2 {
  background-size: cover;
  background-position: center;
  height: 75vh;
}

.custom-title5 {
  font-weight: bold;
  font-size: x-large;
  color: #e7e7e7;
  line-height: 2;
}

.custom-title6 {
  font-weight: bold;
  font-size: x-large;
  line-height: 0.5;
}

@media (max-width: 767px) {
  .custom-title5 {
    font-weight: 600;
  }
  .custom-title6 {
    font-weight: 600;
  }
}

.navbar-nav .nav-link:hover {
  color: #d19429 !important;
}

.navbar-nav .nav-link.active {
  color: #d19429 !important;
}

.navbar-nav .nav-link.show {
  color: #d19429 !important;
}

.custom-home-h2 {
  color: #d19429 !important;
  font-weight: bold;
}

.custom-hr{
  color: #8d97ad;
  margin-top: auto;
  padding-bottom: 1rem;
}

.custom-p-V2{
  font-size: large;
  padding-top: 2rem;
  color: inherit;
  max-width: 95%;
  line-height: 1.4;
}

@media (max-width: 767px) {
  .custom-home-h2 {
    font-size: large;
    font-weight: 800;
  }
  .custom-p-V2{
    font-size: medium;
    max-width: 100%;
  }
}

.custom-testimony-card {
  margin-top: 15px !important;
  margin-bottom: -2% !important;
}

.custom-testimony-avatar {
  height: 40px !important;
}

.custom-testimony-card-image{
  margin-top: -3%;
  width: 100%;
}

.custom-testimony-card-image:hover{
  animation: scaleIn 0.8s ease-in-out forwards;
}

.custom-testimony-card-image-min{
  margin-top: 1%;
  margin-right: 1%;
  width: 32.335%;
}

.custom-testimony-banner{
  background: white;
  border-radius: 15px;
  padding-bottom: 5%;
  padding-top: 1%;
}

.custom-contact-div {
  background-color: inherit;
}

.custom-whoarewe-button {
  margin-left: 4%;
  background-color: #d19429 !important;
  border-color: transparent !important;
  border-radius: unset !important;
}

.custom-whoarewe-button:hover{
  background-color: #8d97ad !important;
  color: #000000 !important;
}

.custom-whoarewe-title {
  color: #d19429;
  text-align: left;
  line-height: 0.5;
}

.pagination .page-item.active {
  background-color: #d19429 !important;
}

.custom-navbar {
  background-color: transparent !important;
  height: 100px;
}

/* Media query for mobile screens */
@media (max-width: 767px) {
  .custom-navbar {
    height: auto !important;
  }
}



.custom-navbar.scrolled {
  background-color: #fff !important;
}

.custom-testimony-button {
  color: #8d97ad;
  font-size: inherit;
  background: none;
  border: none;
  padding: inherit;
  cursor: pointer;
  opacity: 0.8;
  padding-left: 5%;
}

.custom-testimony-button:hover {
  color: #d19429;
}

/* Css blog style */

.custom-image-blog{
  width: 100%;
}

.custom-blog-button {
  color: #8d97ad;
  font-size: small;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.custom-blog-button:hover {
  color: #d19429;
  border-bottom-style: solid;
  border-bottom-color: #d19429;
}

.dropdown-item:hover {
  background-color: #fff;
  color: #d19429;
}

.custom-blog-col:hover {
  transition: transform 0.3s ease;
}

.custom-image-blog:hover{
  animation: scaleIn 0.8s ease-in-out forwards;
}

/*  */

.custom-contact-button {
  height: 35px;
  background-color: #d19429;
  border-radius: 5px;
  border-style: none;
  color: #e7e7e7;
}

.custom-contact-button:hover {
  background-color: #fff;
  color: #d19429 !important;
}

.custom-home-button {
  position: absolute;
  background-color: #d19429;
  border: none;
  color: #e7e7e7;
}

.custom-home-button:hover {
  background-color: #e7e7e7;
  color: #d19429;
}

.custom-dropdown-navbar.dropdown {
  color: #d19429;
}

.card-img-top {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}



.custom-pool-image {
  width: 200px;
}

.custom-pool-p {
  font-size: small;
}

.custom-service-a {
  float: right;
  font-size:small;
  color: #8d97ad;
}

.custom-service-a:hover {
  font-weight: bold;
  color: #d19429;
}

.custom-quote-image {
  width: 100%;
}

.accordion-button:not(.collapsed) {
  background-color: #d3861c !important;
  color: #ffffff !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #d3861c !important;
  color: #fff !important;
}

.nav-tabs .nav-link {
  color: #000000 !important;
  background-color: #fff;
  font-weight: 600;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

/* Service style accordion */
.custom-service-bloc {
  background-color: inherit;
}

.accordion-item:last-of-type .accordion-button.collapsed{
  color: #565454;
  font-weight: bold;
}

.accordion-item:first-of-type .accordion-button{
  color: #565454;
  font-weight: bold;
}

/*  */
.custom-nav-item {
  width: 180px;
  text-align: center;
}

@media ( max-width: 767px ) {
  .custom-nav-item {
    width: 116px;
    font-size: xx-small;
    text-align: center;
  }
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.icon-hamburger {
  height: 50px;
  border: none !important;
}

.navbar-toggler {
  border: none !important;
}

.custom-navbar-brand {
  margin: auto !important;
  padding: unset !important;
}

/* Navbar spacing between link */
.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 25px !important;
  padding-left: 25px !important;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

@media (max-width: 767px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}
/* end navbar */

.wrap-effect-1 .item-identite:nth-of-type(1) {
  background-color: #8d97ad;
  position: relative;
}

.wrap-effect-1 .item-identite:nth-of-type(2) {
  background-color: #bebaa3;
  position: relative;
}

.wrap-effect-1 .item-identite:nth-of-type(3) {
  background-color: #c5bebd;
  position: relative;
}

.blocx {
  max-height: 325px;
  min-height: 250px;
}

.contenu {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 2s ease-in-out;
  height: 0px;
}

.blocx2:hover .contenu2 {
  visibility: visible;
  opacity: 1;
  height: auto;
}

.blocx3:hover .contenu3 {
  visibility: visible;
  opacity: 1;
  height: auto;
}

.blocx1:hover .contenu1 {
  visibility: visible;
  opacity: 1;
  height: auto;
}

.custom-home-p {
  text-align: left !important;
}

.custom-p1 {
  text-align: left !important;

}

/* Remove icon arrow on navbar menu >  */
.dropdown-toggle::after {
  display: none !important;
}

.dropend .dropdown-toggle::after {
  display: none !important;
}

/* Custom style to hide the dropdown by default */
/* .custom-dropdown-navbar .dropdown-menu {
  display: none;
} */

/* Custom style to show the dropdown when the parent is hovered */
/* .custom-dropdown-navbar:hover .dropdown-menu {
  display: block;
} */


/* Paragraph style */
.custom-p-style {
  text-align: left;
}

@media (max-width: 767px) {
  .custom-p-style {
    text-align: left;
  }
}


/*Service*/
.custom-row-service {
  height: 150px;
}

@media (max-width: 767px) {
  .custom-row-service {
    height: 75px;
  }
}

/*Contact*/
.custom-fond-contact {
  height: 45vh;
}

.custom-title-contact {
  font-weight: bold;
  color: #565454;
  font-size: large;
}

.custom-h-contact {
  font-size: 50px;
  font-weight: bold;
  color: #fff;
  line-height: 2;
  padding-left: 3.5vw;
}

@media (max-width: 767px) {
  .custom-fond-contact {
    height: 20vh;
  }

  .custom-title-contact {
    font-size: smaller;
  }

  .custom-h-contact {
    font-size: 30px ;
    font-weight: bold;
    line-height: 1;
    padding-left: 1vw;
  }
}


/* Start style card gallery */
.custom-gallery-row {
  display: flex;
}

.custom-gallery-row.pool-our-more-offers-row{
  margin-bottom: -10vh;
}

.custom-gallery-col {
  flex: 1;
  overflow: hidden;
  position: relative;
  transition: flex 0.70s;
  height: 97vh;
}

/* Style gallery pool && building css */
.custom-gallery-col.custom-gallery-col-2 {
  height: 53vh;
}


.custom-gallery-col:hover {
  flex: 1.40;
}

.custom-gallery-col:hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 12px;
  width: 100%;
  height: 90%;
  background: rgba(0, 0, 0, 0.3);
}

.custom-gallery-col.pool-our-more-offers-col:hover::before {
  margin-top: 50vh;
  height: 37vh !important;
}

.custom-gallery-h4 {
  position: absolute;
  top: 70%;
  left: 8%;
  text-align: left;
  color: #fff;
  opacity: 1;
  transition: opacity 0.5s;
  font-weight: 800;
  transition: top 0.2s;
}

.custom-gallery-description {
  position: absolute;
  top: 25%;
  left: 8%;
  right: 8%;
  text-align: left;
  color: #fff;
  opacity: 0;
  transition: opacity 0.5s;
}

.custom-gallery-col:hover .custom-gallery-description {
  opacity: 1;
  top: 15%;
}

.custom-gallery-col:hover .custom-gallery-h4 {
  opacity: 1;
  top: 5%;
  left: 8%;
  width: 90%;
}

.custom-gallery-col:hover .custom-gallery-h4.pool-our-more-offers{
  top: 60% !important;
}

.custom-gallery-col:hover .custom-gallery-description.pool-our-more-offers{
  top: 65% !important;
}

.custom-gallery-img {
  width: 90%;
  height: 90%;
}

@media (max-width: 767px) {
  .custom-gallery-row {
    flex-direction: column;
    margin-bottom: 0;
    height: auto;
  }
  .custom-gallery-col.pool-our-more-offers-col:hover::before {
    margin-top: 0px !important;
    height: 90vh !important;
  }
}

@media (max-width: 992px) {
  .custom-gallery-description {
    max-width: 400px !important;
  }
}

@media (max-width: 768px) {
  .custom-gallery-description {
    max-width: 350px !important;
  }
}

.custom-gallery-price-2 {
  color: #d19429;
  font-weight: bold;
  font-size: large;
}

.custom-gallery-a{
  font-size: small;
  color: #d19429;
  text-align: left;
}

.custom-gallery-a:hover{
  font-weight: bold;
  color: #8d97ad;
}

/* End style card gallery */

/* Animation */
/* Full image fond with 2 title and Paragraph */
.custom-fond-image {
  position: relative;
  overflow: hidden;
}

.home-custom-h2,
.home-custom-h2-2,
.home-custom-p,
.custom-whoarewe-button {
  opacity: 0;
}

.home-custom-h2 {
  animation: fadeInRightToLeft 1s ease-in-out forwards;
}

.home-custom-h2-2 {
  animation: fadeInRightToLeft 1.5s ease-in-out forwards;
}

.home-custom-p {
  animation: bounceIn 1.5s ease-in-out forwards;
}

.custom-whoarewe-button {
  animation: scaleIn 1.5s ease-in-out forwards;
}

.custom-h-contact {
  animation: scaleIn 1.5s ease-in-out forwards;
}

.custom-title-contact{
  animation: bounceIn 2s ease-in-out forwards;
}

@keyframes fadeInRightToLeft {
  0% {
    opacity: 0.3;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0.1;
    transform: translateY(-100%);
  }
  50% {
    opacity: 0.3;
    transform: translateY(15%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scaleIn {
  0% {
    opacity: 0.2;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
/* end animation */

/* Custom col whoarewe*/
.custom-col-whoarewe{
  /* background: linear-gradient(180deg, #000000, #ffffffda); */
  background-color: #e7e7e7;
}

.custom-col-whoarewe-2{
  background: linear-gradient(180deg, #e7e7e7, #e7e7e7);
}
/*  */
.custom-footer-bg{
  background-color: #e7e7e783;
}

.custom-hr-2{
  margin-top: 4%;
  opacity: 0.1 !important;
}

/* Quote style */
.custom-quote-p{
  text-align: left !important;
}

/* Proil card style */
.custom-profil-image-card{
  width: 200px;
  height: auto;
}

.custom-profil-image-card:hover{
  cursor: pointer;
  opacity: 0.8;
}

@media (max-width: 767px) {
  .custom-profil-image-card{
    width: 160px;
  }
}

.poste {
  text-align: left;
  margin-top: -5%;
}

.custom-card-img-profil {
  max-width: 100%;
}

.custom-card-name-profil {
  color: #d3861c;
  font-weight: bolder;
  font-size: small;
  text-align: left;
}

@media (max-width: 767px) {
  .custom-card-name-profil {
    font-size: x-small;
  }
  .poste {
    font-size: x-small;
    margin-top: -7%;
  }
}

/* .full-screen-toggle{
  height: 100vh;
  width: 100vw;
  background-color: white;
  align-content: baseline;
  margin-top: -2%;
} */


/* Map style */
.leaflet-container{
  height: 100%;
}
@media (max-width: 767px) {
  .leaflet-container{
    height: 75vw;
    position: absolute;
  }
}


/*  */
.custom-home-a{
  font-weight: 600;
  font-size: small;
  color: #d19429;
  text-align: left;
}

.custom-home-a:hover{
  color: #8d97ad;
}